import { colorSemaphoreAvance, normalizePercentage, formatoMiles, colorSemaphore } from "../../../helpers/Helpers";

const ZonasTableIndividual = (props) => {
    let suma1 = 0, suma2 = 0, suma3 = 0, suma4 = 0, suma5 = 0,
    suma13 = 0, suma6 = 0, suma7 = 0, suma8 = 0, suma9 = 0,
    suma10 = 0, suma14 = 0, sumaCN = 0, suma15 = 0, suma20 = 0,
    sumaCNCP = 0, suma16 = 0, suma21 = 0, sumaCNCPSF = 0, suma17 = 0,
    suma22 = 0, suma18 = 0, suma23 = 0, suma11 = 0, suma19 = 0,
    suma24 = 0, suma12 = 0, sumaTotalRechazado = 0, sumaCNCPTotal = 0, sumaCNTotal = 0,
    sumaCNCPSFTotal = 0, porcentajeRetencion = 0;
    
  const {
    data,
    cadenaComercial,
    handleZona,
    mes,
    usuario,
    mesActual,
    avanceIdeal,
    setTiendaNombre,
  } = props;

  return (
    <table id="ranking-zona" className="table table-hover table-sm">
        <thead>
            <tr>
                {cadenaComercial === '1' &&
                    <>
                    <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                        Rank
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                        ID
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark sticky-col">
                        Tienda
                    </th>
                    </>
                }

                {cadenaComercial === '2' &&
                    <>
                    <th rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle table-dark">
                        Rank
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle table-dark">
                        ID
                    </th>
                    <th rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle table-dark sticky-col">
                        Tienda
                    </th>
                    </>
                }
            
                <th
                    colSpan={usuario.puede.ver.estadisticas.otros ? "7" : "1"}
                    scope="colgroup"
                    className="even-title"
                >
                    Reactivaciones
                </th>
                <th
                    colSpan={usuario.puede.ver.estadisticas.otros ? "7" : "1"}
                    scope="colgroup"
                    className="table-dark"
                >
                    Migraciones
                </th>
                {cadenaComercial === '2' &&
                    <th colSpan="6" scope="colgroup" className="even-title">
                    Cuentas nuevas
                    </th>
                }
                {cadenaComercial === '1' &&
                    <th colSpan={cadenaComercial === '1' ? '13' : '2'} scope="colgroup" className="even-title">
                    Cuentas nuevas
                    </th>
                }
                {cadenaComercial === '1' &&
                    <th
                    scope="col"
                    className="text-sm align-middle even-title px-1rem"
                    rowSpan="0"
                    >
                        % DE RETENCIÓN
                    </th>
                }
                {cadenaComercial === '2' &&
                    <th
                    scope="col"
                    className="text-sm align-middle even-title px-1rem"
                    rowSpan="0"
                    >
                        % DE RETENCIÓN
                    </th>
                }
                <th
                    colSpan={cadenaComercial === '1' ? '10' : '2'}
                    scope="colgroup" className="table-dark">
                    Rechazo inicial
                </th>
                {cadenaComercial === '2' &&
                    <th
                    scope="col"
                    className="text-sm align-middle even-title px-1rem"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                    >
                    {(data[0].banderaEstrella === 1) ? '¿Ganaste estrella este mes?' : '¿Si hoy fuera cierre de mes, ganarías estrella?'}
                    {/* {mes === mesNow && anio === anioNow
                    ? "Si hoy fuera cierre de mes, ganarías estrella de:"
                    : "En este mes, ganaste estrella de:"} */}
                    </th>
                }
                {cadenaComercial === '1' &&
                    <th
                    scope="col"
                    className="text-sm align-middle even-title px-1rem"
                    rowSpan={cadenaComercial === '1' ? '3' : '2'}
                    >
                    {(data[0].banderaEstrella === 1) ? '¿Ganaste estrella este mes?' : '¿Si hoy fuera cierre de mes, ganarías estrella?'}
                    </th>
                }
                {cadenaComercial === '2' &&
                    <th colSpan="4" scope="colgroup" className="table-dark">
                    IPPF
                    </th>
                }
                {cadenaComercial === '1' &&
                    <th colSpan="4" scope="colgroup" className="table-dark">
                    IPPF
                    </th>
                }
            </tr>

            <tr className="table-bordered">
                {/* Reactivaciones */}
                {usuario.puede.ver.estadisticas.otros && (
                    <>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">

                        Meta Global <br />(C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                        Avance Global <br />(C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                        Avance <br />Crédito
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                        Meta CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                        Avance <br />CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                        Faltan
                    </th>
                    </>
                )}
                <th
                    scope="col"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                    className="text-sm align-middle sticky-header even"
                >
                    % avance
                </th>

                {/* Migraciones */}
                {usuario.puede.ver.estadisticas.otros && (
                    <>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                        Meta Global <br />(C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                        Avance Global <br /> (C + CS)
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                        Avance<br /> Crédito
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                        Meta CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                        Avance CS
                    </th>
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                        Faltan
                    </th>
                    </>
                )}

                <th
                    scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'}
                    className="text-sm align-middle sticky-header odd"
                >
                    % avance
                </th>
                {cadenaComercial === '2' &&
                    <>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                        Total CN
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                        # Básico con <br />
                        {'LC > 5000'}
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                        % Básico con <br />
                        {'LC > 5000'}
                    </th>
                    </>
                }
                {cadenaComercial === '1' &&
                    <>
                    <th colSpan="3" scope="col" className="align-middle sticky-header even">
                        CN
                    </th>
                    <th colSpan="3" scope="col" className="align-middle sticky-header even">
                        CN CP
                    </th>
                    <th colSpan="3" scope="col" className="align-middle sticky-header even">
                        CN CP <br />SF
                    </th>
                    <th colSpan="3" scope="col" rowSpan='1' className="align-middle sticky-header even">
                        CN <br />total
                    </th>
                    </>
                }
                {cadenaComercial === '2' &&
                    <th scope="col" className="align-middle sticky-header even" rowSpan={cadenaComercial === '1' ? '2' : '0'}>
                    Cuentas canceladas <br /> en el mismo mes
                    </th>
                }
                {cadenaComercial === '1' &&
                    <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Cuentas totales canceladas <br /> en el mismo mes
                    </th>
                }
                {/* Rechazo inicial */}
                {cadenaComercial === '1' &&
                    <>
                    <th
                        scope="col"
                        colSpan='2'
                        className="align-middle sticky-header even px-1rem"
                    >
                        CN
                    </th>
                    <th
                        scope="col"
                        colSpan='2'
                        className="align-middle sticky-header even px-1rem"
                    >
                        CN CP
                    </th>
                    <th
                        colSpan='2'
                        scope="col"
                        className="align-middle sticky-header even px-1rem"
                    >
                        CN CP <br /> SF
                    </th>
                    </ >
                }
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    %<br />cancelación
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Cuentas totales<br />rechazadas
                </th>
                <th
                    scope="col"
                    className="align-middle sticky-header even px-1rem"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                >
                    Anterior
                </th>
                <th
                    scope="col"
                    className="align-middle sticky-header even px-1rem"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                >
                    Actual
                </th>
                {/* IPPF */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Meta
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    {(mes === mesActual) ? 'Al mes anterior' : 'Al cierre de mes'}
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Faltante
                </th>
            </tr>
            {cadenaComercial === '1' &&
                <tr>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    %
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    %
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    %
                    </th>
                </tr>
            }
        </thead>

        <colgroup>
            <col span="4" />
            <col
            span={usuario.puede.ver.estadisticas.otros ? "6" : "1"}
            className="even"
            />
            <col span={usuario.puede.ver.estadisticas.otros ? "6" : "1"} />
            <col span="5" className="even" />
            <col span="1" />
            <col span="4" className="even" />
        </colgroup>

        <tbody className="table-bordered">
            {data.map((row, i) => {
            // Reactivaciones
            suma1 = suma1 + parseFloat(row.metaReactivacion);
            suma2 = suma2 + parseFloat(row.avanceReactivacion);
            suma3 = suma3 + parseFloat(row.avanceReactivacionesAsesorCredito);
            suma4 = suma4 + parseFloat(row.avanceReactivacionesAsesorCss);
            suma5 = suma5 + parseFloat(row.faltanReactivaciones);
            suma13 = suma13 + parseFloat(row.metaReactivacionCs);
            // Migraciones
            suma6 = suma6 + parseFloat(row.metaMigracion);
            suma7 = suma7 + parseFloat(row.avanceMigracion);
            suma8 = suma8 + parseFloat(row.avanceMigracionesAsesorCredito);
            suma9 = suma9 + parseFloat(row.avanceMigracionesAsesorCss);
            suma10 = suma10 + parseFloat(row.faltanteMigraciones);
            suma14 = suma14 + parseFloat(row.metaMigracionCs);
            
            // Cuentas nuevas
            // Cuentas nuevas CN
            const cnTotal = parseFloat(row.cuentasNuevas?.cn.total);
            const cnBasicoNumero = parseFloat(row.cuentasNuevas?.cn.totalBasico);
            const cnBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cn.totalBasicoPorcentaje);
            sumaCN = sumaCN + parseFloat(row.cuentasNuevas?.cn.total);
            suma15 = suma15 + parseFloat(row.cuentasNuevas?.cn.totalBasico);
            suma20 = parseFloat([(suma15 * 100) / sumaCN]);

            // Cuentas nuevas CNCP
            const cncpTotal = parseFloat(row.cuentasNuevas?.cncp.total);
            const cncpBasicoNumero = parseFloat(row.cuentasNuevas?.cncp.totalBasico);
            const cncpBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncp.totalBasicoPorcentaje);
            sumaCNCP = sumaCNCP + parseFloat(row.cuentasNuevas?.cncp.total);
            suma16 = suma16 + parseFloat(row.cuentasNuevas?.cncp.totalBasico);
            suma21 = parseFloat([(suma16 * 100) / sumaCNCP]);

            // Cuentas nuevas CNCPSF
            const cncpsfTotal = parseFloat(row.cuentasNuevas?.cncpsf.total);
            const cncpsfBasicoNumero = parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
            const cncpsfBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncpsf.totalBasicoPorcentaje);
            sumaCNCPSF = sumaCNCPSF + parseFloat(row.cuentasNuevas?.cncpsf.total);
            suma17 = suma17 + parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
            suma22 = parseFloat([(suma17 * 100) / sumaCNCPSF]);

            // CN Total
            const cnTotalTotal = parseFloat(row.cuentasNuevas?.total);
            const cnTotalBasicoNumero = parseFloat(row.cuentasNuevas?.totalBasico);
            const cnTotalBasicoPorcentaje = parseFloat(row.cuentasNuevas?.totalBasicoPorcentaje);
            sumaCNTotal = sumaCNTotal + parseFloat(row.cuentasNuevas?.total);
            suma18 = suma18 + parseFloat(row.cuentasNuevas?.totalBasico);
            suma23 = parseFloat([(suma18 * 100) / sumaCNTotal]);

            // Cuentas nuevas Tlc
            const cncpTotalTlc = parseFloat(row.cuentasNuevas?.tlc.total);
            const cncpNumeroTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasico);
            const cncpPorcentajeTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasicoPorcentaje);
            suma11 = suma11 + parseFloat(row.cuentasNuevas?.tlc.total);
            suma19 = suma19 + parseFloat(row.cuentasNuevas?.tlc.totalBasico);
            suma24 = parseFloat([(suma19 * 100) / suma11]);

            // Cuentas Canceladas
            suma12 = suma12 + parseFloat(row.canceladas);
            sumaTotalRechazado = sumaTotalRechazado + parseFloat(row.totalCuentasRechazo);
            sumaCNCPTotal = sumaCNCPTotal + parseFloat(row.rechazoTotalCNCP);
            sumaCNCPSFTotal = sumaCNCPSFTotal + parseFloat(row.rechazoTotalCNCPSF);
            porcentajeRetencion = porcentajeRetencion + parseFloat(row.promedioRetencion);

            return (
                <tr
                key={i}
                className="cursor-pointer"
                onClick={() => {
                    handleZona(row.id, row.tienda);
                    setTiendaNombre(row.tienda)
                }}
                >
                {/* General */}
                <td>{row.rank}</td>
                <td>{row.id}</td>
                <td className="sticky-col">{row.tienda}</td>

                {/* Reactivación */}
                {usuario.puede.ver.estadisticas.otros && (
                    <>
                    <td className="even">{row.metaReactivacion}</td>
                    <td>{row.avanceReactivacion}</td>
                    <td>{row.avanceReactivacionesAsesorCredito}</td>
                    <td>{row.metaReactivacionCs}</td>
                    <td>{row.avanceReactivacionesAsesorCss}</td>
                    <td>{row.faltanReactivaciones}</td>
                    </>
                )}
                <td
                    className={`bg-${colorSemaphoreAvance(
                    row.porcentajeReactivacion,
                    avanceIdeal
                    )}`}
                >
                    {normalizePercentage(row.porcentajeReactivacion)}
                </td>

                {/* Migraciones */}
                {usuario.puede.ver.estadisticas.otros && (
                    <>
                    <td>{row.metaMigracion}</td>
                    <td>{row.avanceMigracion}</td>
                    <td>{row.avanceMigracionesAsesorCredito}</td>
                    <td>{row.metaMigracionCs}</td>
                    <td>{row.avanceMigracionesAsesorCss}</td>
                    <td>{row.faltanteMigraciones}</td>
                    </>
                )}
                <td
                    className={`bg-${colorSemaphoreAvance(
                    row.porcentajeMigracion,
                    avanceIdeal
                    )}`}
                >
                    {normalizePercentage(row.porcentajeMigracion)}
                </td>
                {cadenaComercial === '2' &&
                    <>
                    <td  className="align-middle text-md even">{cncpTotalTlc}</td>
                    <td  className="align-middle text-md even">{cncpNumeroTlc}</td>
                    <td style={{ backgroundColor: cncpPorcentajeTlc <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}  className="align-middle text-md even">{normalizePercentage(cncpPorcentajeTlc)}</td>
                    </>
                }
                {/* Cuentas nuevas CN, CNCP, CNCPSF, CNTotal */}
                {cadenaComercial === '1' &&
                    <>
                    {/* Total */}
                    <td>{cnTotal}</td>
                    {/* Básico # */}
                    <td>{cnBasicoNumero}</td>
                    {/* Básico % */}
                    <td style={{ backgroundColor: cnBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnBasicoPorcentaje)}</td>
                    {/* Total */}
                    <td>{cncpTotal}</td>
                    {/* Básico # */}
                    <td className="even">{cncpBasicoNumero}</td>
                    {/* Básico % */}
                    <td style={{ backgroundColor: cncpBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpBasicoPorcentaje)}</td>
                    {/* Total */}
                    <td>{cncpsfTotal}</td>
                    {/* Básico # */}
                    <td>{cncpsfBasicoNumero}</td>
                    {/* Básico % */}
                    <td style={{ backgroundColor: cncpsfBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpsfBasicoPorcentaje)}</td>
                    {/* CNTotal */}
                    <td className="even">{cnTotalTotal}</td>
                    <td className="even">{cnTotalBasicoNumero}</td>
                    <td style={{ backgroundColor: cnTotalBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }} className="even">{normalizePercentage(cnTotalBasicoPorcentaje)}</td>
                    </>
                }
                <td className="even">{row.canceladas}</td>
                {cadenaComercial === '1' &&
                    <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                }
                {/* Rechazo incial */}
                {cadenaComercial === '1' &&
                    <>
                    <td>{row.rechazoTotalCN}</td>
                    <td>{normalizePercentage(row.rechazoInicialCN)}</td>
                    <td>{row.rechazoTotalCNCP}</td>
                    <td>{normalizePercentage(row.rechazoInicialCP)}</td>
                    <td>{row.rechazoTotalCNCPSF}</td>
                    <td>{normalizePercentage(row.rechazoInicialCPSF)}</td>
                    </>
                }
                <td className="even">{normalizePercentage(row.porcentajeCanceladas)}</td>
                <td>{row.totalCuentasRechazo}</td>
                {cadenaComercial === '2' &&
                    <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                }
                <td>{normalizePercentage(row.rechazoInicalAnterior)}</td>
                <td
                    className={`bg-${colorSemaphore(
                    "rechazo",
                    row.rechazoInicalActual,
                    cadenaComercial
                    )}`}
                >
                    {normalizePercentage(row.rechazoInicalActual)}
                </td>

                {/* Estrella? */}
                <td>
                    {row.ganaEstrella === "No ganaría"
                    ? "No"
                    : row.ganaEstrella}
                </td>

                {/* IPPF */}
                <td>{normalizePercentage(row.metaIppf)}</td>
                <td style={{ backgroundColor: row.metaIppfAnterior >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>
                    {normalizePercentage(row.metaIppfAnterior)}
                </td>
                <td
                    className={`bg-${colorSemaphore(
                    "ippf",
                    row.ippfFaltante,
                    cadenaComercial
                    )}`}
                >
                    {normalizePercentage(row.ippfFaltante)}
                </td>
                </tr>
            );
            })}
        </tbody>
        
        <tfoot>
            <tr className="bg-white border-0">
                <td></td>
                <td></td>
                <td></td>
                {usuario.puede.ver.estadisticas.otros && (
                <>
                    <td className="fw-bold">{formatoMiles(suma1)}</td>
                    <td className="fw-bold">{formatoMiles(suma2)}</td>
                    <td className="fw-bold">{formatoMiles(suma3)}</td>
                    <td className="fw-bold">{formatoMiles(suma13)}</td>
                    <td className="fw-bold">{formatoMiles(suma4)}</td>
                    <td className="fw-bold">{formatoMiles(suma5)}</td>
                </>
                )}
                <td></td>
                {usuario.puede.ver.estadisticas.otros && (
                <>
                    <td className="fw-bold">{formatoMiles(suma6)}</td>
                    <td className="fw-bold">{formatoMiles(suma7)}</td>
                    <td className="fw-bold">{formatoMiles(suma8)}</td>
                    <td className="fw-bold">{formatoMiles(suma14)}</td>
                    <td className="fw-bold">{formatoMiles(suma9)}</td>
                    <td className="fw-bold">{formatoMiles(suma10)}</td>
                </>
                )}
                <td></td>
                {cadenaComercial === '1' &&
                <>
                    <td className="fw-bold">{formatoMiles(sumaCN)}</td>
                    <td className="fw-bold">{formatoMiles(suma15)}</td>
                    <td className="fw-bold">{formatoMiles(suma20.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(sumaCNCP)}</td>
                    <td className="fw-bold">{formatoMiles(suma16)}</td>
                    <td className="fw-bold">{formatoMiles(suma21.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(sumaCNCPSF)}</td>
                    <td className="fw-bold">{formatoMiles(suma17)}</td>
                    <td className="fw-bold">{formatoMiles(suma22.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(sumaCNTotal)}</td>
                    <td className="fw-bold">{formatoMiles(suma18)}</td>
                    <td className="fw-bold">{formatoMiles(suma23.toFixed(2))}%</td>
                </>
                }
                {cadenaComercial === '2' &&
                <>
                    <td className="fw-bold">{formatoMiles(suma11)}</td>
                    <td className="fw-bold">{formatoMiles(suma19)}</td>
                    <td className="fw-bold">{formatoMiles(suma24.toFixed(2))}%</td>
                </>
                }
                <td className="fw-bold">{formatoMiles(suma12)}</td>
                {cadenaComercial === '2' &&
                <>
                    <td className="fw-bold"></td>
                </>
                }
                {cadenaComercial === '1' &&
                <td className="fw-bold"></td>
                }
                <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
                {cadenaComercial === '1' &&
                <>
                    <td></td>
                    <td className="fw-bold">{formatoMiles(sumaCNCPTotal)}</td>
                    <td></td>
                    <td className="fw-bold">{formatoMiles(sumaCNCPSFTotal)}</td>
                    <td></td>
                    <td></td>
                    <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
                </>
                }
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tfoot>
    </table>
  )
}

export default ZonasTableIndividual
