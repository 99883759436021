import { colorSemaphoreAvance, normalizePercentage, formatoMiles, colorSemaphore } from "../../../helpers/Helpers";

const ZonasTable = (props) => {
  let suma1 = 0, suma2 = 0, suma3 = 0, suma4 = 0, suma5 = 0, suma11 = 0,
      suma6 = 0, suma7 = 0, suma8 = 0, suma9 = 0, suma10 = 0, suma12 = 0,
      sumaCNTotal = 0, sumaCNCPTotal = 0, sumaCNCPSFTotal = 0, sumaTotalRechazado = 0, porcentajeRetencion = 0;

  const {
    data,
    cadenaComercial,
    handleGlobal,
    mes,
    mesActual,
    avanceIdeal
  } = props;

  return (
    <table id="ranking-nacional" className="table table-hover table-sm">
        <thead>
            <tr>
            <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                Rank
            </th>
            <th rowSpan={cadenaComercial === '1' ? '3' : '2'} className="align-middle table-dark">
                Zona
            </th>
            <th colSpan="7" scope="colgroup" className="even-title">
                Reactivaciones
            </th>
            <th colSpan="7" scope="colgroup" className="table-dark">
                Migraciones
            </th>
            <th
                colSpan={cadenaComercial === '1' ? '9' : '2'}
                scope="colgroup" className="even-title">
                Rechazo inicial
            </th>
            <th colSpan="1" scope="colgroup" rowSpan='0' className="text-sm align-middle even-title px-1rem">
                % DE RETENCIÓN
            </th>
            <th colSpan="3" scope="colgroup" className="table-dark">
                IPPF
            </th>
            </tr>

            <tr>
            {/* Reactivaciones */}
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Meta Global<br />(C + CS)
            </th>
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Avance Global<br />(C + CS)
            </th>
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Avance <br />Crédito
            </th>
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Meta CS
            </th>
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Avance <br /> CS
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                Faltan
            </th>
            <th
                rowSpan={cadenaComercial === '1' ? '2' : '0'}
                scope="col"
                className="text-sm align-middle sticky-header even"
            >
                % avance
            </th>

            {/* Migraciones */}
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Meta Global <br /> (C + CS)
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Avance Global <br /> (C + CS)
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Avance <br />Crédito
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Meta CS
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Avance <br /> CS
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Faltan
            </th>
            <th
                scope="col"
                className="text-sm align-middle sticky-header odd"
                rowSpan={cadenaComercial === '1' ? '2' : '0'}
            >
                % avance
            </th>

            {/* Rechazo inicial */}
            {cadenaComercial === '1' &&
                <>
                <th colSpan="2" scope="col" className="align-middle sticky-header even">
                    CN
                </th>
                <th colSpan="2" scope="col" className="align-middle sticky-header even">
                    CN CP
                </th>
                <th colSpan="2" scope="col" className="align-middle sticky-header even">
                    CN CP <br />SF
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Cuentas totales <br />rechazadas
                </th>
                </>
            }
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                Anterior
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                Actual
            </th>
            {/* IPPF */}
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                Meta
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                {(mes === mesActual) ? 'Al mes anterior' : 'Al cierre de mes'}
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                Faltante
            </th>
            </tr>
            {cadenaComercial === '1' &&
            <tr>
                <th scope="col" className="align-middle sticky-header even">
                Cantidad
                </th>
                <th scope="col" className="align-middle sticky-header even">
                %
                </th>
                <th scope="col" className="align-middle sticky-header even">
                Cantidad
                </th>
                <th scope="col" className="align-middle sticky-header even">
                %
                </th>
                <th scope="col" className="align-middle sticky-header even">
                Cantidad
                </th>
                <th scope="col" className="align-middle sticky-header even">
                %
                </th>
            </tr>
            }
        </thead>

        <colgroup>
            <col span="2" />
            <col span="6" className="even" />
            <col span="6" />
            <col span="2" className="even" />
        </colgroup>

        <tbody className="table-bordered">
            {data.map((row, i) => {
            // Reactivaciones
            suma1 = suma1 + parseFloat(row.metaReactivacion);
            suma2 = suma2 + parseFloat(row.avanceReactivaciones);
            suma3 = suma3 + parseFloat(row.avanceCreditoReactivacion);
            suma4 = suma4 + parseFloat(row.avanceCssReactivacion);
            suma5 = suma5 + parseFloat(row.faltanReactivaciones);
            suma11 = suma11 + parseFloat(row.metaReactivacionCs);
            // Migraciones
            suma6 = suma6 + parseFloat(row.metaMigracion);
            suma7 = suma7 + parseFloat(row.avanceMigraciones);
            suma8 = suma8 + parseFloat(row.avanceCreditoMigracion);
            suma9 = suma9 + parseFloat(row.avanceCssMigracion);
            suma10 = suma10 + parseFloat(row.faltanMigraciones);
            suma12 = suma12 + parseFloat(row.metaMigracionCs);
            sumaCNTotal = sumaCNTotal + parseFloat(row.rechazoTotalCN);
            sumaCNCPTotal = sumaCNCPTotal + parseFloat(row.rechazoTotalCNCP);
            sumaCNCPSFTotal = sumaCNCPSFTotal + parseFloat(row.rechazoTotalCNCPSF);
            sumaTotalRechazado = sumaTotalRechazado + parseFloat(row.totalCuentasRechazo);
            porcentajeRetencion = porcentajeRetencion + parseFloat(row.promedioRetencion);
            return (
                <tr
                key={i}
                className="cursor-pointer"
                onClick={() => {
                    handleGlobal(row.zona, row.zona);
                }}
                >
                {/* General */}
                <td className="sticky-col">{row.rank}</td>
                <td>{row.zona}</td>

                {/* Reactivación */}
                <td>{row.metaReactivacion}</td>
                <td>{row.avanceReactivaciones}</td>
                <td>{row.avanceCreditoReactivacion}</td>
                <td>{row.metaReactivacionCs}</td>
                <td>{row.avanceCssReactivacion}</td>
                <td>{row.faltanReactivaciones}</td>
                <td
                    className={`bg-${colorSemaphoreAvance(
                    row.porcentajeReactivacion,
                    avanceIdeal
                    )}`}
                >
                    {normalizePercentage(row.porcentajeReactivacion)}
                </td>

                {/* Migraciones */}
                <td>{row.metaMigracion}</td>
                <td>{row.avanceMigraciones}</td>
                <td>{row.avanceCreditoMigracion}</td>
                <td>{row.metaMigracionCs}</td>
                <td>{row.avanceCssMigracion}</td>
                <td className="odd">{row.faltanMigraciones}</td>
                <td
                    className={`bg-${colorSemaphoreAvance(
                    row.porcentajeMigracion,
                    avanceIdeal
                    )}`}
                >
                    {normalizePercentage(row.porcentajeMigracion)}
                </td>

                {/* Rechazo incial */}
                {
                    cadenaComercial === '1' &&
                    <>

                    <td>{row.rechazoTotalCN}</td>
                    <td>{normalizePercentage(row.rechazoInicialCN)}</td>
                    <td>{row.rechazoTotalCNCP}</td>
                    <td>{normalizePercentage(row.rechazoInicialCNCP)}</td>
                    <td>{row.rechazoTotalCNCPSF}</td>
                    <td>{normalizePercentage(row.rechazoInicialCNCPSF)}</td>
                    <td>{row.totalCuentasRechazo}</td>
                    </>
                }
                <td>{normalizePercentage(row.rechazoInicalAnterior)}</td>
                <td
                    className={`bg-${colorSemaphore(
                    "rechazo",
                    row.rechazoInicalActual,
                    cadenaComercial
                    )}`}
                >
                    {normalizePercentage(row.rechazoInicalActual)}
                </td>

                {/* % DE RETENCIÓN */}
                <td>{normalizePercentage(row.promedioRetencion)}</td>

                {/* IPPF */}
                <td>{normalizePercentage(row.ippfMeta)}</td>
                <td style={{ backgroundColor: row.ippfAnterior >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>
                    {normalizePercentage(row.ippfAnterior)}
                </td>
                <td
                    className={`bg-${colorSemaphore(
                    "ippf",
                    row.ippfFaltante,
                    cadenaComercial
                    )}`}
                >
                    {normalizePercentage(row.ippfFaltante)}
                </td>
                </tr>
            );
            })}
        </tbody>

        <tfoot>
            <tr className="bg-white border-0">
                <td></td>
                <td></td>
                <td className="fw-bold">{formatoMiles(suma1)}</td>
                <td className="fw-bold">{formatoMiles(suma2)}</td>
                <td className="fw-bold">{formatoMiles(suma3)}</td>
                <td className="fw-bold">{formatoMiles(suma11)}</td>
                <td className="fw-bold">{formatoMiles(suma4)}</td>
                <td className="fw-bold">{formatoMiles(suma5)}</td>
                <td></td>
                <td className="fw-bold">{formatoMiles(suma6)}</td>
                <td className="fw-bold">{formatoMiles(suma7)}</td>
                <td className="fw-bold">{formatoMiles(suma8)}</td>
                <td className="fw-bold">{formatoMiles(suma12)}</td>
                <td className="fw-bold">{formatoMiles(suma9)}</td>
                <td className="fw-bold">{formatoMiles(suma10)}</td>
                <td></td>
                {cadenaComercial === '1' && <>
                <td className="fw-bold">{formatoMiles(sumaCNTotal)}</td>
                <td></td>
                <td className="fw-bold">{formatoMiles(sumaCNCPTotal)}</td>
                <td></td>
                <td className="fw-bold">{formatoMiles(sumaCNCPSFTotal)}</td>
                <td></td>
                <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
                <td></td>
                <td></td>
                <td></td>
                </>
                }
            </tr>
        </tfoot>
    </table>
  );
};

export default ZonasTable;
